import constants from "../constants/index";
const initState = {
  asset: null,
  projectId: null,
  accountId: null,
  project: null,
  categories: null,
  category: null,
  businesses: null,
  business: null,
  ads: null
}

const PlayerStore = (state = initState, action) => {
  switch (action.type) {
    case constants.getAsset:
      return {
        ...state,
        asset: action.payload.asset
      };
    case constants.getProject:
      return {
        ...state,
        project: action.payload.project    
      };    
    case constants.setProjectById:
      return {
        ...state,
        project: action.payload.project    
      };    
    case constants.setAds:
      return {
        ...state,
        ads: action.payload.ads
      };
    case constants.setCategories:
      return {
        ...state,
        categories: action.payload.categories
      };
    case constants.setCategory:
      return {
        ...state,
        category: action.payload.category
      };
    case constants.setBusinesses:
      return {
        ...state,
        businesses: action.payload.businesses
      };
    case constants.setBusiness:
      return {
        ...state,
        business: action.payload.business
      };
    default:
      return state;
  }
 }

export default PlayerStore;
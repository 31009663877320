export default {
  getAsset: "GET_ASSET",
  getProject: "GET_PROJECT",
  setProject: "SET_PROJECT",
  setProjectById: "SET_PROJECT_BY_ID",
  setCategories: "SET_CATEGORIES",
  setCategory: "SET_CATEGORY",
  setBusinesses: "SET_BUSINESSES",
  setBusiness: "SET_BUSINESS",
  setAds: "SET_ADS"
}
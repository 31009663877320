import React, { useEffect } from 'react';
import { Route } from "react-router";
import LazyRoute from "lazy-route";
import routingData from "./data/Routing.json";
import { useSelector } from "react-redux";
import PlayerActions from "./store/actions/PlayerActions";
import "./assets/scss/main.scss";

const App = (props) => {
  const routes = routingData.routing.map(renderRoute);
  const PlayerStore = useSelector(state => state.PlayerStore);
  const { asset } = PlayerStore;
  const { getAsset } = PlayerActions();

  useEffect(() => { 
    getAsset();

    window.onclick = function(event) {
      interruptPlayer();
    };
    
    window.addEventListener("touchstart", function(event) {
      interruptPlayer();
    }, false);
  }, []);

  return (
    <div className="main">
      {asset ? routes : null}
    </div>
  );

  function renderRoute(route) {
    const component = import('' + route.componentPath),
      exact = route.exact ? route.exact : false;

    return (
      <Route exact={exact} 
        path={route.path}
        render={props => (
          <LazyRoute {...props}  
            component={component} 
          />
        )} 
      /> 
    );
  }

  function interruptPlayer() {
    window.enplug.appStatus.setCanInterrupt(false);
  }
};

export default App;

import { createAction } from 'redux-actions';
import { useDispatch } from 'react-redux';
import Helper from "../helpers/Helper";
import constants from "../constants/index";

let store = null;
const actions = {
  getAsset: createAction(constants.getAsset),
  setProject: createAction(constants.setProject),
  setProjectById: createAction(constants.setProjectById),
  setAds: createAction(constants.setAds),
  setCategories: createAction(constants.setCategories),
  setCategory: createAction(constants.setCategory),
  setBusinesses: createAction(constants.setBusinesses),
  setBusiness: createAction(constants.setBusiness),
};

export default function useActions(accountId = "Local") {
  const dispatch = useDispatch();
  store = new Helper("projects", accountId);

  return {
    getAsset: () => getAsset(dispatch),
    setProject: item => setProject(dispatch, item),
    setProjectById: id => setProjectById(dispatch, id),
    setAds: projectId => setAds(dispatch, projectId),
    setCategories: projectId => setCategories(dispatch, projectId),
    setCategory: item => setCategory(dispatch, item),
    setBusinesses: (projectId, categoryId) => setBusinesses(dispatch, projectId, categoryId),
    setBusiness: item => setBusiness(dispatch, item),
  }
};

function getAsset(dispatch) {
  if (window.enplug) {
    window.enplug.appStatus.start();
    window.enplug.assets.getNext()
    .then(asset => {
      console.log(asset);
      dispatch(actions.getAsset({ asset: asset }));
    })
    .catch(handleError);
  }
}

function setProject(dispatch, item) {
  dispatch(actions.setProject({ project: item }));
}

function setProjectById(dispatch, id) {
  return store.setItem(id)
  .then(response => {
    dispatch(actions.setProjectById({ project: response.data.data }));
  });
}

function setAds(dispatch, projectId) {
  const url = `${store.apiUrl}/${projectId}/ads`;
  return store.setList(url)
  .then(response => {
    dispatch(actions.setAds({ ads: response.data.data }));
  })
}

function setCategories(dispatch, projectId) {
  const url = `${store.apiUrl}/${projectId}/categories`;
  return store.setList(url)
  .then(response => {
    dispatch(actions.setCategories({ categories: response.data.data }));
  })
}

function setCategory(dispatch, item) {
  dispatch(actions.setCategory({ category: item }));
}

function setBusinesses(dispatch, projectId, categoryId) {
  const url = `${store.apiUrl}/${projectId}/businesses`;
  return store.setList(url, { category_id: categoryId })
  .then(response => {
    dispatch(actions.setBusinesses({ businesses: response.data.data }));
  })
}

function setBusiness(dispatch, item) {
  dispatch(actions.setBusiness({ business: item }));
}

function handleError(error) {
  console.log(error);
}
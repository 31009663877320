import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { syncHistoryWithStore } from 'react-router-redux';
import store from "./store/index";

import App from './App.jsx';

const rootEl = document.getElementById('app');

const renderComponent = Component => {
  const browserHistory = require('history').createBrowserHistory();
  const history = syncHistoryWithStore(browserHistory, store);
  window.appHistory = syncHistoryWithStore(browserHistory, store);

  ReactDOM.render(
    <Provider store={store}>
      <Router history={history}>
        <Component />
      </Router>
    </Provider>,
    rootEl,
  );
};

renderComponent(App);
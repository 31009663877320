import settings from "../../data/Settings.json";
import axios from "axios";

export default class Helper {
  constructor(url = "", accountId = null) {
    let apiUrl = settings.apiUrl;

    if (env == "production") {
      apiUrl = settings.apiUrlProduction;
    }

    this.accountId = accountId;
    this.multipartHeader = {
      headers: { 'Content-Type': 'multipart/form-data' }
    };

    this.apiUrl = `${apiUrl}/${url}`;
  }

  setList = (url = null, params = null) => {
    let data = {
      headers: { 'Authorization': this.accountId },
      params: {
        limit: params && params.limit ? params.limit : this.itemsPerPage
      }
    };

    if (params) {
      data.params = Object.assign(params, data.params);
    }

    // Check empty properties
    Object.keys(data.params).forEach(key => {
      if (!data.params || data.params[key] == "") {
        delete data.params[key];
      }
    });

    this.loadingShow();
    return axios.get(this.checkUrl(url), data)
    .finally(this.loadingHide);
  }

  setItem = (id, url = null, params = null) => {
    let data = { 
      headers: { 'Authorization': this.accountId },
      params: {}
    };

    if (params) {
      data.params = Object.assign(data.params, params);
    }

    this.loadingShow();
    return axios.get(`${this.checkUrl(url)}/${id}`, data)
    .finally(this.loadingHide);
  }

  addItem = (values, url = null) => {
    const headers = Object.assign({ 'Authorization': this.accountId }, this.multipartHeader.headers);
    const formData = this.getFormData(values);

    this.loadingShow();
    return axios.post(this.checkUrl(url), formData, { headers: headers })
    .finally(this.loadingHide);
  }

  editItem = (values, url = null) => {
    const headers = Object.assign({ 'Authorization': this.accountId }, this.multipartHeader.headers);
    const formData = this.getFormData(values);

    this.loadingShow();
    return axios.post(`${this.checkUrl(url)}/${values.id}`, formData, { headers: headers })
    .finally(this.loadingHide);
  }

  deleteItem = (id, url = null) => {
    this.loadingShow();
    return axios.delete(`${this.checkUrl(url)}/${id}`, {
      headers: { 'Authorization': this.accountId }
    })
    .finally(this.loadingHide);
  }

  // Additional functions
  checkUrl = (url) => {
    return !url ? this.apiUrl : url;
  }

  getFormData = (obj) => {
    let formData = new FormData();

    for ( const key in obj ) {
      if (!Array.isArray(obj[key])) {
        formData.append(key, obj[key]);
      } else {
        obj[key].forEach((item, index) => {
          formData.append(`${key}[${index}]`, item);
        });
      }
    }

    return formData;
  }

  loadingShow = () => {
    if (window.enplug.dashboard) {
      window.enplug.dashboard.pageLoading(true);
    }
  }

  loadingHide = () => {
    if (window.enplug.dashboard) {
      window.enplug.dashboard.pageLoading(false);
    }
  }
}